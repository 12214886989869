import Vue, { AsyncComponent } from 'vue'
import Router, { RouteConfig as BaseRouteConfig } from 'vue-router'

Vue.use(Router)

type RouteConfig<T> = BaseRouteConfig & {
  name: T
  component?: AsyncComponent
  children?: RouteConfig<T>[]
}

const routes = [
  {
    path: '/',
    name: 'Front',
    component: () => import('@/views/Front.vue'),
  },
  {
    path: '/:id',
    name: 'Portals/View',
    component: () => import('@/views/BookingPortal.vue'),
  },
  {
    path: '/:id/m/:ticket/:secret',
    name: 'Bookings/Modify',
    component: () => import('@/views/BookingPortal.vue'),
  },
  {
    path: '/:id/r/:token',
    name: 'Bookings/Request',
    component: () => import('@/views/BookingPortal.vue'),
  },
  {
    path: '/:portal/b/:ticket/:secret',
    name: 'Bookings/View',
    component: () => import('@/views/BookingView.vue'),
  },
  {
    path: '/:portal/b/:ticket/:secret/:action',
    name: 'Bookings/View/WithAction',
    component: () => import('@/views/BookingView.vue'),
  },
] as RouteConfig<string>[]

const router = new Router({
  routes,
  mode: 'history',
})

export default router
