import Vue from 'vue'
import 'reflect-metadata'
import 'normalize.css/normalize.css'
import router from './router'
import vuetify from '@/plugins/vuetify'
import './plugins/fontawesome'
import VueHead from 'vue-head'
import App from './App.vue'
import vueAxios from './plugins/vueaxios'
import i18n from '@/i18n.ts'

Vue.use(VueHead)
Vue.config.productionTip = false

require('./common/default.sass')

/* eslint-disable no-new */
const vue = new Vue({
  router,
  i18n,
  vueAxios,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

export default vue
