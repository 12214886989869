import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n.ts'

Vue.use(Vuetify)
const theme = {
  primary: '#eba844',
  secondary: '#FC8100',
  accent: '#914500',
  error: '#b71c1c',
  navbarBackground: '#000000',
}

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  lang: {
    t: (key: string, ...params: Array<string | number>): string => {
      const result = i18n.t(key, params)
      if (/^\$vuetify/.test(key)) {
      }
      return result as string
    },
  },
  theme: {
    themes: {
      light: theme,
      dark: theme,
    },
  },
})
