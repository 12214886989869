// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="config.d.ts" />

const apiRoot = '//' + document.location.host + '/api'
const frontendAddress = document.location.href.split('://')[1].split('/')[0]
let environment = ''

if (frontendAddress.match(/localhost|\.local\./)) {
  environment = 'development'
} else if (frontendAddress.match(/\.stage\./)) {
  environment = 'development'
} else {
  environment = 'production'
}

export const API_ROOT = apiRoot
export const ENVIRONMENT = environment
export const VERSION = __EONTYRE_VERSION__ // injected by webpack from package.json
